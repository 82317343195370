.backdrop {
  position: fixed;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.3);
  backdrop-filter: blur(1px);
  display: flex;
  align-items: center;
  justify-content: center;
  opacity: 1;
  z-index: 99999;

  .content {
    position: relative;
    padding-top: 9px;
    padding-left: 12px;
    padding-right: 12px;
    box-sizing: border-box;
    width: 904px;
    max-height: 600px;
    box-shadow: 0 3px 6px rgba(0, 0, 0, 0.16);
    background-color: #fff;
    border-radius: 5px;
    transform: translateY(0);
    opacity: 1;
    overflow-y: auto;
    .modalHeader {
      .closeIcon {
        display: block;
        width: 25px;
        height: 25px;
        margin-left: auto;
        font-size: 0;
        background-image: url(../../assets/images/closeIcon.svg);
        cursor: pointer;
      }
    }
  }
}
