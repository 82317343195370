.Sidebar {
  width: 210px;
  background: #25282C;
  padding-top: 80px;
  position: fixed;
  top: 0;
  bottom: 0;
  height: 100%;
  box-sizing: border-box;

  .SidebarInner {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;
    height: 100%;
  }

  .SidebarBox {
    margin-top: 54px;
    width: 100%;

    .profile {
      .profileImageBox {
        width: 70px;
        height: 70px;
        display: flex;
        justify-content: center;
        align-items: center;
        background: #C4C4C4;
        border-radius: 50%;
        margin: 0 auto;
      }
      .profileInfo {
        margin-top: 20px;
        text-align: center;
        .userName {
          font-style: normal;
          font-weight: normal;
          font-size: 18px;
          line-height: 22px;
          color: #FFFFFF;
          
        }
        .accountType {
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          color: #C4C4C4;
        }
      }
    }
    .navigation {
      margin-top: 40px;
      display: flex;
      justify-content: center;
      .listContainer {
        padding: 0;
      }
    }
  }
  .liItem {
    list-style: none;
    display: flex;
    align-items: center;
    margin-bottom: 30px;
    .rect {
      display: block;
      width: 20px;
      height: 20px;
      background: #C4C4C4;
    }
    .navItem {
      margin-left: 30px;
      text-decoration: none;
      font-size: 16px;
      line-height: 19px;
      color: #FFFFFF;
    }
  }
  .btnContainer {
    display: flex;
    justify-content: center;
    .LogOutBtn {
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      text-decoration-line: underline;
      color: #FFFFFF;
      outline: none;
      background-color: transparent;
      border: none;
      cursor: pointer;
    }
  }
}