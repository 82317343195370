.ProjectsPage {
  margin-top: 34px;

  .btnContainer {
    width: 100%;
    display: flex;
    justify-content: flex-end;
  }

  .noProjects {
    color: #fff;
    text-align: center;
    font-weight: 500;
    margin-top: 50px;
  }
}