.Content {
  margin-top: 69px;
  .header {
    display: flex;
    justify-content: space-between;
    margin-bottom: 48px;
    h1 {
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      color: #ffffff;
      white-space: nowrap;
    }
    .btnContainer {
      display: flex;
      flex-direction: row;
      button {
        width: 180px;
        height: 40px;
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        color: #ffffff;
        background-color: #7e57c2;
        border-radius: 5px;
        border: none;
        &.closeFilters {
          margin-right: 20px;
          color: #7e57c2;
          background-color: #e5e5e5;
          border: 2px solid #7e57c2;

          &.isRenders {
            margin-right: 0;
          }
        }
      }
      .filtersIcon {
        display: block;
        width: 29px;
        height: 37px;
        margin-right: 24px;
        font-size: 0;
        background-image: url(../../assets/images/settingsIcon.svg);
        cursor: pointer;
      }
    }
  }
  .filters {
    display: flex;
    justify-content: space-between;
    align-items: center;
    margin-bottom: 27px;

    &.isContent {
      justify-content: flex-end;
      i {
        margin-left: 83px;
      }
    }
    
    i {
      display: block;
      width: 60px;
      height: 50px;
      font-size: 0;
      background-position: center;
      background-repeat: no-repeat;
      cursor: pointer;

      &.active {
        border: 2px solid #7e57c2;
      }

      &.sixteenNine,
      &.nineSixteen,
      &.oneOne {
        width: 56px;
        height: 54px;
      }
      &.sixteenNine {
        background-image: url(../../assets/images/16_9.svg);
      }
      &.nineSixteen {
        background-image: url(../../assets/images/9_16.svg);
      }
      &.oneOne {
        background-image: url(../../assets/images/1_1.svg);
      }
      &.sixSeconds {
        background-image: url(../../assets/images/6s.svg);
      }
      &.fifteenSeconds {
        background-image: url(../../assets/images/15s.svg);
      }
      &.thirtySeconds {
        background-image: url(../../assets/images/30s.svg);
      }
      &.fast {
        background-image: url(../../assets/images/fast.svg);
      }
      &.slow {
        background-image: url(../../assets/images/slow.svg);
      }
    }
  }
  .content {
    display: flex;
    flex-wrap: wrap;
    margin-right: -60px;
  }
}
