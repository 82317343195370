.PlacesList {
  margin-top: 106px;
  width: 100%;

  .table {
    width: 100%;
    color: #fff;
    border-collapse: separate;

    th, td {
      text-align: left;
      padding: 14px 20px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      background-color: #25282C;
    }
  }
  .tableHeader {
  }
}