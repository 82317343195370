body {
  margin: 0;
  background-color: #16181A!important;

  font-family: "Inter", sans-serif!important;
  font-style: normal!important;
  font-weight: normal!important;
}

.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link {
  color: #fff!important;
  background-color: #7E57C2!important;
  border: none!important;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
}
.nav-tabs .nav-item.show .nav-link, .nav-tabs .nav-link.active {
  color: #000!important;
  background-color: #E5E5E5!important;
}

.nav-tabs {
  border-bottom: none;
  justify-content: space-between;

  .nav-item {
    max-width: 270px;
    width: 100%;
    button {
      padding: 14px;
      display: block;
      width: 100%;
      text-align: center;
      border-radius: 10px 10px 0px 0px;
    }
  }
}
