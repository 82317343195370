.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;

  .Card {
    background-color: transparent;
    border: none;
  }

  .formFieldContainer {
    margin-bottom: 26px;
  }

  .formBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 64px;
  }
}
