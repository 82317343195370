.AutoEditForm {
  padding-top: 32px;
  padding-left: 68px;

  .videoSettingsContainer {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding-right: 68px;
    .videoSettings {
      display: flex;
      flex-direction: column;
      .selectors {
        display: flex;
        justify-content: space-between;
        width: 100%;
        padding-left: 91px;
        padding-right: 109px;
      }
      display: flex;
      align-items: center;
      width: 520px;
      height: 214px;
      margin-bottom: 43px;
      padding-top: 13px;
      background-color: #e5e5e5;
      box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 5px;
      .nameInput {
        width: 430px;
        height: 30px;
        margin-bottom: 10px;
        padding-left: 13px;
        padding-right: 13px;
        background-color: rgba(196, 196, 196, 0.5);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
      }
    }
    .buttonContainer {
      margin-top: 24px;
      margin-bottom: 39px;
    }
  }

  .form {
    display: flex;
    margin-bottom: 143px;
    .inputs {
      display: flex;
      flex-direction: column;
      .nameInput {
        width: 450px;
        height: 30px;
        margin-bottom: 10px;
        padding-left: 13px;
        padding-right: 13px;
        background-color: rgba(196, 196, 196, 0.5);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
      }
      .textarea {
        width: 450px;
        height: 244px;
        resize: none;
        padding-left: 20px;
        padding-right: 20px;
        padding-top: 16px;
        padding-right: 16px;
        background-color: #e5e5e5;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
      }
    }
    .selectors {
      width: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      .selectContainer {
        position: relative;
        width: 215px;
        margin-bottom: 51px;
        &::after {
          content: '';
          width: 15px;
          height: 7px;
          background-image: url(../../../assets/images/selectArrow.svg);
          position: absolute;
          top: 11px;
          right: 7px;
          pointer-events: none;
        }
        select {
          width: 70px;
          height: 30px;
          margin-left: 15px;
          padding-left: 25px;
          appearance: none;
          font-style: normal;
          font-weight: 600;
          font-size: 16px;
          line-height: 19px;
          background-color: transparent;
          border: 1px solid black;
          option {
            margin-bottom: 33px;
            font-style: normal;
            font-weight: 600;
            font-size: 16px;
            line-height: 19px;
          }
        }
      }
    }
  }
  .buttonContainer {
    width: 738px;
    margin-bottom: 62px;
    display: flex;
    justify-content: center;
  }
}
