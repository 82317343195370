.AssetPreview {
  position: relative;
  width: 85px;
  height: 60px;
  cursor: pointer;

  .overlay {
    position: absolute;
    z-index: 1;
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    display: flex;
    justify-content: center;
    align-items: center;
    background-color: rgba(196, 196, 196, 0.4);
    .playIcon {
      display: block;
      width: 20px;
      height: 20px;
      font-size: 0;
      background-image: url(../../../assets/images/playIcon.svg);
    }
  }

  video {
    width: 100%;
    height: 100%;
    object-fit: cover;
  }
}
