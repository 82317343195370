.Video {
  box-sizing: border-box;
  width: 630px;
  height: 150px;
  display: flex;
  margin-bottom: 25px;
  padding-top: 25px;
  padding-left: 40px;
  padding-right: 40px;
  padding-bottom: 25px;
  background-color: #e5e5e5;
  box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
  border-radius: 5px;
  .inputs {
    padding-left: 40px;
    input {
      width: 100%;
      height: 30px;
      margin-bottom: 10px;
      padding-left: 11px;
      padding-right: 11px;
      background-color: #c4c4c4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
    textarea {
      width: 100%;
      height: 60px;
      padding-left: 11px;
      padding-right: 11px;
      resize: none;
      background-color: #c4c4c4;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    }
  }
  .preview {
    position: relative;
    width: 140px;
    height: 100px;
    video {
      width: 140px;
      height: 100px;
      object-fit: cover;
    }
    .overlay {
      position: absolute;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 100%;
      height: 100%;
      background-color: rgba(196, 196, 196, 0.4);
      button {
        width: 50px;
        height: 50px;
        font-size: 0;
        background-color: #e5e5e5;
        background-image: url(../../../../assets/images/playButton.svg);
        background-position: 19px center;
        background-repeat: no-repeat;
        border-radius: 50%;
        border: none;
      }
    }
  }
}
