.ExpCardDateField {
  margin-bottom: 0;
  width: 178px;
  .formControl {
    position: relative;
    border: none;
    box-sizing: border-box;
    width: 100%;
    height: 40px;
    padding: 9px 22px;
    font-family: Inter;
    font-size: 18px;
    line-height: 22px;
    color: rgba(255, 255, 255, 0.5);
    background: rgba(63, 65, 67, 0.5);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    outline: none;

    &.isInvalid {
      background: rgba(252, 7, 7, 0.1);
      border: 2px solid #FC0707;
      box-sizing: border-box;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      border-radius: 10px;
    }
  }

  .formControl:read-only {
    background: #3F4143;
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    pointer-events: none;
  }

  .formControl:focus {
    color: rgba(255, 255, 255, 0.5);
    background: rgba(63, 65, 67, 0.5);
    box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;
    outline: none;
    border-color: none;
  }
}
