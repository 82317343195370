.autoCropContainer {
  margin: 28px 57px 0;
  .progressBar {
    margin: 0 auto;
    display: flex;
    height: 25px;
    font-size: 0.75rem;
    width: 700px;
    background: #ffffff;
    border: 1px solid #979494;
    box-sizing: border-box;
    border-radius: 20px;

    div {
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #7e57c2;
      transition: width 0.6s ease;
    }
  }

  .uploading {
    text-align: center;
  }

  .settingsContainer {
    margin-top: 83px;
    display: flex;
    justify-content: space-between;

    .item {
      background: #979494;
      padding: 6px;
      width: 250px;
      height: 234px;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;

      .aspectRatio1 {
        width: 238px;
        height: 132px;
        background: #c4c4c4;
        margin-bottom: 31px;
      }
      .aspectRatio2 {
        width: 96px;
        height: 156px;
        background: #c4c4c4;
        margin-bottom: 7px;
      }
      .aspectRatio3 {
        width: 149px;
        height: 149px;
        background: #c4c4c4;
        margin-bottom: 14px;
      }
      .boxInput {
        text-align: center;
        .text {
          font-size: 18px;
          line-height: 22px;
          color: #000000;
        }
      }
    }
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    margin-top: 45px;
    margin-bottom: 57px;
    button {
      padding: 9px 22px;
      width: 160px;
    }
  }
}
