.ProjectPage {
  margin-top: 54px;

  .projectSection {
    display: flex;
    justify-content: space-between;

    .boxProject {
      color: #FFFFFF;
      .projectName {
        font-size: 24px;
        line-height: 29px;
      }
      .projectDescription {
        margin-top: 8px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .btnContainerAction {
      margin-top: 15px;
      
      .btnContainer {
        margin-bottom: 20px;
        button {
          width: 160px;
          padding-top: 9px;
          padding-bottom: 9px;
          border-radius: 5px;
        }
      }
    }
  }

  .searchSection {
    margin-top: 68px;
    display: flex;
    justify-content: space-between;

    .searchResultBox {
      display: flex;
      flex-wrap: wrap;
      gap: 35px 35px;
      padding: 32px;
      box-sizing: border-box;
      width: 437px;
      height: 222px;
      background-color: #25282C;
      overflow-y: auto;

      &::-webkit-scrollbar {
        width: 30px;
      }
      
      &::-webkit-scrollbar-track {
        border-radius: 20px;
        width: 5px;
        box-shadow: inset 0 0 10px 10px #C4C4C4;
        border: solid 10px transparent;
      }
      
      &::-webkit-scrollbar-thumb {
        width: 5px;
        border-radius: 20px;
        box-shadow: inset 0 0 10px 10px #FFFFFF;
        border: solid 10px transparent;
      }

      .videoBox {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: #C4C4C4;
        width: 85px;
        height: 60px;
        cursor: pointer;

        .playIcon {
          display: block;
          width: 20px;
          height: 20px;
          font-size: 0;
          background-image: url(../../assets/images/playIcon.svg);
        }
      }
    }

    .searchFilterBox {
      width: 250px;
      height: 222px;
      background-color: #25282C;
      display: flex;
      flex-direction: column;
      /* justify-content: space-between; */
      align-items: center;

      .searchField {
        display: flex;
        align-items: center;
        margin: 17px 4px 0;
        box-sizing: border-box;
        padding: 0 11px;
        background: rgb(63, 65, 67);
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border-radius: 30px;
        max-width: 242px;

        .searchIcon {
          display: block;
          width: 12px;
          height: 12px;
          font-size: 0;
          background-image: url(../../assets/images/search.svg);
          margin-right: 10px;
        }

        .inputField {
          color: rgba(255, 255, 255, 0.5);
          background: transparent;
          border: none;
          outline: none;
          width: 190px;
        }
      }

      .switcherContainer {
        width: 100%;
        margin-top: 15px;
        box-sizing: border-box;
        max-width: 200px;
        .switchItem {
          display: flex;
          justify-content: space-between;
          align-items: center;

          .text {
            font-style: normal;
            font-weight: normal;
            font-size: 14px;
            line-height: 17px;
            color: #FFFFFF;
            margin-right: 70px;
          }
        }
      }

      .btnContainer {
        margin-top: 15px;
        button {
          padding: 7px 11px;
          font-size: 14px;
          line-height: 17px;
        }
      }
    }
  }

  .guiSection {
    margin-top: 100px;
    margin-bottom: 60px;
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background: #25282C;
    height: 553px;

    div {
      font-size: 36px;
      line-height: 44px;
      color: #FFFFFF;
    }
  }

}