.ProjectPage {
  margin-top: 54px;

  .projectSection {
    display: flex;
    justify-content: space-between;

    .boxProject {
      color: #FFFFFF;
      .projectName {
        font-size: 24px;
        line-height: 29px;
      }
      .projectDescription {
        margin-top: 8px;
        font-size: 18px;
        line-height: 22px;
      }
    }

    .boxProjectEdit {
      margin-top: 47px;
      padding: 35px 26px 20px 26px;
      background: #25282C;

      .editOption {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: 20px;

        .text {
          color: #C4C4C4;
          font-style: normal;
          font-weight: normal;
          font-size: 16px;
          line-height: 19px;
          margin-right: 30px;
        }

        .progressBar {
          display: flex;
          height: 14px;
          font-size: .75rem;
          background-color: #C4C4C4;;
          width: 260px;
          margin-right: 53px;

          div {
            display: flex;
            flex-direction: column;
            justify-content: center;
            overflow: hidden;
            color: #fff;
            text-align: center;
            white-space: nowrap;
            background-color: #7E57C2;
            transition: width .6s ease;
          }
        }

        .boxImg {
          margin-right: 30px;
          cursor: pointer;
        }
      }
    }

    .btnContainerAction {
      margin-top: 15px;
      
      .btnContainer {
        margin-bottom: 20px;
        button {
          width: 160px;
          padding-top: 9px;
          padding-bottom: 9px;
          border-radius: 5px;
        }
      }
    }
  }

}