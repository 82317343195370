.DashboardPage {
  margin-top: 186px;

  .container {
    display: grid;
    grid-template-rows: 1fr 1fr;
    grid-template-columns: 1fr 1fr;
    grid-gap: 96px;
    justify-items: center;
    max-width: 1100px;
    margin: 0 auto;

    .item {
      width: 250px;
      height: 190px;
      background-color: #25282C;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      cursor: pointer;
    }

    .autoCropIcon {
      display: block;
      width: 56px;
      height: 54px;
      font-size: 0;
      background-image: url(../../assets/images/autoCrop.svg);
      margin-right: 10px;
    }

    .autoEditIcon {
      display: block;
      width: 60px;
      height: 50px;
      font-size: 0;
      background-image: url(../../assets/images/autoEdit.svg);
      margin-right: 10px;
    }

    .searchIcon {
      display: block;
      width: 64px;
      height: 64px;
      font-size: 0;
      background-image: url(../../assets/images/searchIcon.svg);
      margin-right: 10px;
    }

    .autoGraphicsIcon {
      display: block;
      width: 70px;
      height: 66px;
      font-size: 0;
      background-image: url(../../assets/images/autoGraphics.svg);
      margin-right: 10px;
    }

    .itemText {
      margin-top: 25px;
      font-style: normal;
      font-weight: normal;
      font-size: 24px;
      line-height: 29px;
      color: #FFFFFF;
    }
  }
}