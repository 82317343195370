.SearchForm {
  max-width: 540px;
  margin: 0 auto;

  &.uploadTab {
    margin: 0 auto 80px;
  }

  .tabsContainer {
    margin-top: 35px;

    &.isFiles {
      display: none;

      & > div {
        background-color: transparent;
      }
    }
  }

  .TabItem {
    height: 300px;
    width: 100%;
    background-color: #e5e5e5;
    border-radius: 0px 0px 10px 10px;
  
    &.isFiles {
      background-color: transparent;
    }
  
    .existingContainer {
      padding-top: 19px;
  
      .searchForm {
        display: flex;
        align-items: center;
        padding: 0 8px 0 25px;
  
        .searchFormContainer {
          max-width: 373px;
          width: 100%;
          margin-right: 10px;
        }
  
        .searchFormInput {
          width: 100%;
          background: #DCDCDC;
          box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
          border-radius: 30px;
          border: none;
          outline: none;
          padding: 7px 14px;
        }
      }

      .searchResultBox {
        display: flex;
        flex-wrap: wrap;
        gap: 15px 15px;
        padding: 22px 10px 10px 30px;
        box-sizing: border-box;
        overflow-y: auto;
        max-height: 200px;
        /* max-width: 540px; */
        /* margin: 0 auto; */
  
        &::-webkit-scrollbar {
          width: 30px;
        }
        
        &::-webkit-scrollbar-track {
          border-radius: 20px;
          width: 5px;
          box-shadow: inset 0 0 10px 10px #C4C4C4;
          border: solid 10px transparent;
        }
        
        &::-webkit-scrollbar-thumb {
          width: 5px;
          border-radius: 20px;
          box-shadow: inset 0 0 10px 10px #FFFFFF;
          border: solid 10px transparent;
        }
  
        .videoBox {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          background-color: #C4C4C4;
          width: 140px;
          height: 100px;
          cursor: pointer;
  
          .playIcon {
            display: block;
            width: 50px;
            height: 50px;
            font-size: 0;
            background-image: url(../../../assets/images/playIconExisting.svg);
          }
        }
      }
    }
  }
}


.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;

  button {
    padding: 9px 22px;
    width: 160px;
    margin-bottom: 10px;
  }
}