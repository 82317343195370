.Containter {
  margin-top: 80px;
  display: flex;
}

.mainContainer {
  margin-left: 210px;
  padding: 0 124px;
  box-sizing: border-box;
  width: 100%;
}