.SettingsPage {
  margin-top: 60px;

  .container {
    max-width: 380px;
    display: flex;
    flex-direction: column;

    .section {
      width: 100%;
    }

    .header {
      margin-top: 37px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #FFFFFF;
      display: flex;
      justify-content: space-between;
      align-items: center;

      &.topHeader {
        margin-top: 0;
      }

      .pencilIcon {
        display: block;
        width: 19px;
        height: 19px;
        font-size: 0;
        background-image: url(../../assets/images/pencil.svg);
        cursor: pointer;
      } 
    }

    .formFieldContainer {
      margin-top: 27px;
    }

    .boxToField {
      display: flex;
      justify-content: space-between;
      align-items: center;
      box-sizing: border-box;

      .cvv {
        width: 180px;
      }
    }

    .btnContainer {
      margin-top: 70px;
      button {
        box-sizing: border-box;
        padding: 10px 21px;
        border-radius: 5px;
        font-size: 16px;
        line-height: 19px;
      }
    }
  }
}