.CreateProject {
  max-width: 540px;
  margin: 0 auto;

  .projectContainer {
    margin: 20px auto 0;
    max-width: 520px;
    width: 100%;

    .form {
      /* max-width: 400px; */
      width: 100%;
    }

    .fieldContainer {
      margin-top: 20px;
    }

    .formInput {
      width: 100%;
      padding: 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.4);
      background: #E5E5E5;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      outline: none;
      border: none;

      &.formDescription {
        min-height: 255px;
        height: 100%;
        resize: none;
        margin-top: 20px;
      }
    }
  }
}

.btnContainer {
  display: flex;
  justify-content: center;
  margin-top: 40px;
  margin-bottom: 30px;

  button {
    padding: 9px 22px;
    width: 160px;
    margin-bottom: 10px;
  }
}

