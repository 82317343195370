.autoEditContainer {
  margin: 0 57px;
  .progressBar {
    margin: 0 auto;
    display: flex;
    height: 25px;
    font-size: 0.75rem;
    width: 700px;
    background: #ffffff;
    border: 1px solid #979494;
    box-sizing: border-box;
    border-radius: 20px;

    div {
      border-radius: 20px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      overflow: hidden;
      color: #fff;
      text-align: center;
      white-space: nowrap;
      background-color: #7e57c2;
      transition: width 0.6s ease;
    }
  }

  .uploading {
    text-align: center;
  }

  .formContainer {
    margin: 20px auto 0;
    max-width: 520px;
    width: 100%;

    .form {
      width: 100%;
    }

    .formContainer {
      margin-top: 25px;
    }

    .formInput {
      width: 100%;
      padding: 10px 16px;
      font-style: normal;
      font-weight: normal;
      font-size: 16px;
      line-height: 19px;
      color: rgba(0, 0, 0, 0.4);
      background: #e5e5e5;
      box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
      outline: none;
      border: none;

      &.formDescription {
        min-height: 118px;
        height: 100%;
        resize: none;
        margin-top: 13px;
      }
    }
  }

  .settingsContainer {
    margin-top: 29px;
    background: #e5e5e5;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 5px;
    padding: 18px 34px;
    box-sizing: border-box;
    display: flex;
    justify-content: space-between;

    .item {
      .text {
        font-style: normal;
        font-weight: normal;
        font-size: 16px;
        line-height: 19px;
        margin-bottom: 6px;
        color: #000000;
      }
    }
  }

  .btnContainer {
    display: flex;
    justify-content: center;
    margin-top: 29px;
    margin-bottom: 27px;
    button {
      padding: 9px 22px;
      width: 160px;
    }
  }
}
