$md-radio-checked-color: #7E57C2;
$md-radio-border-color: #7E57C2;
$md-radio-size: 25px;
$md-radio-checked-size: 15px;

.RadioButton {
  margin: 16px 0;

  input[type="radio"] {
    display: none;
      
    &:checked + label:before {
      border-color: $md-radio-checked-color;   
    }
      
    &:checked + label:after {
      transform: scale(1);
    }
  }
    
  label {
    display: inline-block;
    height:$md-radio-size;
    position: relative;
    padding: 0 ($md-radio-size + 10px);
    margin-bottom: 0;
    cursor: pointer;
    vertical-align: bottom;
      
    &:before, &:after {
      position: absolute;            
      content: '';  
      border-radius: 50%;
      transition: all .1s ease;
      transition-property: transform, border-color;
    }
      
    &:before {
      left: 0;
      top: 0;
      width: $md-radio-size;
      height: $md-radio-size;
      border: 2px solid $md-radio-border-color;
    }
      
    &:after {
      top: $md-radio-size / 2 - $md-radio-checked-size / 2;
      left: $md-radio-size / 2 - $md-radio-checked-size / 2;
      width:$md-radio-checked-size;
      height:$md-radio-checked-size;
      transform: scale(0);
      background:$md-radio-checked-color;
    }

    &.isCrop {
      padding: 0 12px;
    }
  }
}