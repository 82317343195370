.AutoCropForm {
  display: flex;
  flex-direction: column;
  align-items: center;
  .header {
    display: flex;
    justify-content: flex-end;
    align-items: center;
    width: 100%;
    margin-bottom: 25px;
    .selectAll {
      position: relative;
      top: 12px;
      margin-left: 14px;
      margin-right: 90px;
      cursor: pointer;
      user-select: none;
    }
    .checkboxContainer {
      margin-left: 20px;
    }
  }
}
