.Asset {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  box-sizing: border-box;
  width: 200px;
  height: 184px;
  margin-bottom: 35px;
  margin-right: 60px;
  padding-top: 5px;
  background-color: #979494;
  cursor: pointer;

  &.selected {
    .preview {
      .selectionMark {
        position: absolute;
        width: 33px;
        height: 33px;
        background-image: url(../../../assets/images/selectionMark.svg);
        background-position: center;
        background-repeat: no-repeat;
        background-color: #7e57c2;
        border-radius: 5px;
      }
    }
  }

  .preview {
    position: relative;
    width: 190px;
    height: 137px;
    background-color: #c4c4c4;
    .videoLength {
      position: absolute;
      right: 2px;
      bottom: 2px;
      display: flex;
      justify-content: center;
      align-items: center;
      width: 42px;
      height: 14px;
      background-color: black;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 14px;
      color: white;
    }
    video {
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }
  .footer {
    width: 180px;
    height: 42px;
    display: flex;
    padding-top: 5px;
    h1 {
      font-family: Inter;
      font-style: normal;
      font-weight: normal;
      font-size: 12px;
      line-height: 15px;
      color: #000000;
      overflow: hidden;
      white-space: nowrap;
      text-overflow: ellipsis;
    }
  }
  .emptyCheckbox {
    position: absolute;
    left: 5px;
    width: 33px;
    height: 33px;
    border: 2px solid #7e57c2;
    box-sizing: border-box;
    border-radius: 5px;
  }

  .hoverMenu {
    position: absolute;
    z-index: 1;
    top: 168px;
    width: 100%;
    padding-bottom: 8px;
    background-color: #979494;

    .hoverButtons {
      display: flex;
      justify-content: space-between;
      align-items: center;
      margin-bottom: 27px;
      padding-left: 10px;
      padding-right: 10px;

      button {
        width: 80px;
        height: 30px;
        background-color: #7e57c2;
        font-size: 14px;
        line-height: 17px;
        color: white;
        border-radius: 5px;
        outline: none;
        border: none;
      }

      .deleteIcon {
        display: block;
        width: 20px;
        height: 23px;
        font-size: 0;
        background-image: url(../../../assets/images/deleteIcon.svg);
      }
    }
    .details {
      display: flex;
      justify-content: space-between;
      padding-left: 10px;
      padding-right: 52px;
      .icon {
        width: 27px;
        height: 26px;
        background-image: url(../../../assets/images/icon.svg);
      }

      .videoDetails {
        display: flex;
        flex-direction: column;
        .aspectRatios {
          display: flex;
          margin-bottom: 13px;
          div {
            position: relative;
            width: 25px;
            height: 25px;
            margin-right: 6px;
            background-size: cover;
            background-repeat: no-repeat;

            &.notAvailable {
              &::after {
                content: '';
                position: absolute;
                left: -4px;
                bottom: 10px;
                width: 135%;
                height: 1px;
                background-color: black;
                transform: rotate(-45deg);
              }
            }

            &.nineSixteen {
              background-image: url(../../../assets/images/9_16_dark.svg);
            }
            &.sixteenNine {
              background-image: url(../../../assets/images/16_9_dark.svg);
            }
            &.oneOne {
              background-image: url(../../../assets/images/1_1_dark.svg);
            }
          }
        }
      }

      .pace {
        width: 100%;
        display: flex;
        div {
          position: relative;
          width: 26px;
          height: 25px;
          background-size: contain;
          background-repeat: no-repeat;
          &.notAvailable {
            &::after {
              content: '';
              position: absolute;
              left: -4px;
              bottom: 13px;
              width: 125%;
              height: 1px;
              background-color: black;
              transform: rotate(-45deg);
            }
          }
        }
        .slow {
          background-image: url(../../../assets/images/slowDark.svg);
        }
        .fast {
          margin-right: 12px;
          background-image: url(../../../assets/images/fastDark.svg);
        }
      }
    }
  }
}
