.RenderDetails {
  display: flex;
  flex-direction: column;
  align-items: center;
  padding-top: 22px;

  .mainButtonsContainer {
    display: flex;
    justify-content: space-between;
    padding: 0;
    width: calc(100% - 114px);
    margin-bottom: 57px;
  }

  .content {
    display: flex;
    justify-content: space-between;
    width: 800px;
    height: 381px;
    margin-bottom: 41px;
    padding-top: 28px;
    padding-left: 26px;
    padding-bottom: 30px;
    padding-right: 10px;
    background-color: #e5e5e5;
    box-shadow: 4px 4px 4px rgba(0, 0, 0, 0.25);
    border-radius: 10px;

    .leftColumn {
      width: 383px;
      display: flex;
      flex-direction: column;
      justify-content: space-between;

      input {
        width: 100%;
        height: 30px;
        padding-left: 11px;
        padding-right: 11px;
        background-color: #c4c4c4;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
      }

      .videoContainer {
        position: relative;
        width: 100%;
        height: 263px;

        video {
          width: 100%;
          height: 100%;
          object-fit: cover;
        }

        .overlay {
          position: absolute;
          z-index: 1;
          top: 0;
          left: 0;
          right: 0;
          bottom: 0;
          display: flex;
          justify-content: center;
          align-items: center;
          background-color: rgba(196, 196, 196, 0.4);
          cursor: pointer;

          .playButton {
            width: 137px;
            height: 137px;
            background-image: url(../../../assets/images/playButton.svg);
            background-position: 55px center;
            background-size: 45px;
            background-repeat: no-repeat;
            background-color: #e5e5e5;
            border-radius: 50%;
          }
        }
      }
    }

    .rightColumn {
      display: flex;
      flex-direction: column;
      align-items: center;
      width: 280px;

      textArea {
        width: 280px;
        height: 86px;
        resize: none;
        margin-bottom: 18px;
        padding-top: 6px;
        padding-left: 9px;
        padding-bottom: 6px;
        padding-right: 9px;
        background-color: #c4c4c4;
        box-shadow: inset 0px 4px 4px rgba(0, 0, 0, 0.25);
        border: none;
      }

      .filters {
        display: flex;
        margin-bottom: 19px;

        i {
          display: block;
          width: 32px;
          height: 31px;
          margin-right: 22px;
          font-size: 0;

          &:last-child {
            margin-right: 0;
          }

          &.active {
            border: 1px solid #7e57c2;
          }
        }

        .sixteenNine {
          background-image: url(../../../assets/images/16_9_dark.svg);
        }

        .nineSixteen {
          background-image: url(../../../assets/images/9_16_dark.svg);
        }

        .oneOne {
          background-image: url(../../../assets/images/1_1_dark.svg);
        }
      }

      .buttonsContainer {
        display: flex;
        flex-direction: column;

        button {
          margin-bottom: 29px;

          &:last-child {
            margin-bottom: 0;
          }
        }
      }
    }
  }
}
