.Header {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  background-color: #7E57C2;
  height: 80px;
  padding: 12px 60px;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  justify-content: center;
  box-sizing: border-box;
  z-index: 10000;

  .Logo {
    background-color: #C4C4C4;
    padding: 20px;
    max-width: 70px;
  }
}