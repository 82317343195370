.Video {
  position: relative;
  display: flex;
  //flex-direction: column;
  align-items: flex-start;
  justify-content: flex-start;
  // background-color: #c4c4c4;
  width: 140px;
  height: 100px;
  cursor: pointer;

  video {
    // position: absolute;
    // z-index: -1;

    top: 0;
    left: 0;
    width: 140px;
    height: 100px;
    object-fit: cover;
  }

  .overlay {
    top: 0;
    left: 0;
    right: 0;
    bottom: 0;
    position: absolute;
    background-color: rgba(150, 150, 150, 0.4);
    background-image: url(../../../assets/images/playButtonRound.svg);
    background-position: center;
    background-repeat: no-repeat;
  }

  .containerCheckBox {
    width: 23px;
    height: 23px;
    left: 0;
    top: 0;

    & input {
      position: absolute;
      z-index: -1;
      opacity: 0;
    }
    .checkbox + .checkmark {
      display: inline-flex;
      align-items: center;
      user-select: none;
    }
    .checkbox + .checkmark::before {
      width: 23px;
      height: 23px;
      content: '';
      display: inline-block;
      flex-shrink: 0;
      flex-grow: 0;
      border: 2px solid #7e57c2;
      box-sizing: border-box;
      border-radius: 5px;
      margin-right: 0.5em;
      background-repeat: no-repeat;
      background-position: center center;
      background-size: 50% 50%;
    }
    .checkbox:checked + .checkmark::before {
      border-color: #7e57c2;
      background-color: #7e57c2;
      background-image: url(../../../assets/images/checkbox.svg);
    }
  }
}
