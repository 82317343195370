.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 380px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;

  .Card {
    background-color: transparent;
    border: none;
  }

  .formFieldContainer {
    margin-bottom: 26px;
  }

  .formBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 64px;
  }

  .forgotPassword {
    padding: 0 22px;
    font-size: 12px;
    line-height: 15px;
    text-decoration-line: underline;
    color: #FFFFFF;
  }
}

.createAccount {
  position: absolute;
  bottom: 136px;
  text-align: center;
  font-weight: 600;
  font-size: 16px;
  line-height: 19px;
  text-decoration-line: underline;
  .createAccountBtn {
    color: #7E57C2;
  }
}