.Alert {
  visibility: hidden;
  position: fixed;
  left: 50%;
  top: 160px;
  transform: translate(-50%, -50%);
  width: 350px;
  height: 60px;
  border-radius: 4px;
  display: flex;
  align-items: center;
  text-align: center;
  background-color: #373A3C;
  color: #FC0707;
  z-index: 9999999;

  & .message {
    flex: 80%;
    text-align: center;
    margin: 0;
  }

  &.show {
    visibility: visible;
    animation: fadein 0.5s, fadeout 0.5s 4s;
  }
  
  @keyframes fadein {
    from {
      bottom: 0;
      opacity: 0;
    }
    to {
      bottom: 30px;
      opacity: 1;
    }
  }
  
  @keyframes fadeout {
    from {
      bottom: 30px;
      opacity: 1;
    }
    to {
      bottom: 0;
      opacity: 0;
    }
  }
}


