.FormContainer {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  max-width: 480px;
  width: 100%;
  margin: 0 auto;
  min-height: 100vh;

  .Card {
    background-color: transparent;
    border: none;
  }

  .cardBody {
    padding: 0;
  }

  .descriptionContainer {
    color: #C4C4C4;
    text-align: center;
    font-size: 18px;

    .title {
      font-weight: 700;
      color: #fff;
    }
    .stepDescription {
      margin-top: 20px;
    }
  }

  .form {
    max-width: 380px;
    width: 100%;
    margin: 60px auto 0;
  }

  .formFieldContainer {
    margin-bottom: 26px;
  }

  .formBtnContainer {
    display: flex;
    justify-content: center;
    margin-top: 64px;
  }
}
  