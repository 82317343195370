.DragAndDropUploader {
  .videos {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-bottom: -25px;
    padding-top: 70px;
  }
  .uploader {
    display: flex;
    justify-content: center;

    .dropZone {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: space-between;
      box-sizing: border-box;
      width: 270px;
      height: 270px;
      margin-top: 110px;
      padding-top: 60px;
      padding-bottom: 39px;
      font-style: normal;
      font-weight: normal;
      font-size: 14px;
      line-height: 17px;
      color: #979494;
      border: 1px solid #c4c4c4;
      border-radius: 50%;

      &.isTabInner {
        margin-top: 20px;
        width: 252px;
        height: 252px;
        background-color: #fff;
      }
    }
  }
  .buttonContainer {
    display: flex;
    margin-top: 64px;
    //margin-bottom: 57px;
    justify-content: center;
    button {
      width: 160px;
      height: 40px;
      margin-bottom: 42px;
      font-style: normal;
      font-weight: normal;
      font-size: 18px;
      line-height: 22px;
      color: #ffffff;
      background-color: #7e57c2;
      border-radius: 5px;
      border: none;
    }
  }
}
