.StyledButton {
  position: relative;
  margin: 0 auto;
  background: #7E57C2;
  border-radius: 5px;
  box-sizing: border-box;
  border: 2px solid transparent;
  font-style: normal;
  font-weight: normal;
  font-size: 18px;
  line-height: 22px;
  color: #ffffff;
  overflow: hidden;
  cursor: pointer;

  &:disabled {
    background: rgba(126, 87, 194, 0.35);
    border-radius: 5px;
    color: rgba(255, 255, 255, 0.35);
  }

  &.isAuth {
    width: 180px;
    padding-top: 9px;
    padding-bottom: 9px;
    font-weight: 600;
    border-radius: 10px;
  }
  &.isDashboard {
    font-size: 16px;
    line-height: 19px;
    padding: 6px 11px;
    width: 200px;
  }

  &.isSearch {
    width: 115px;
    border-radius: 5px;
    font-size: 18px;
    line-height: 22px;
    padding: 9px 27px;
  }
  &.isEdit {
    width: 79px;
    border-radius: 5px;
    font-size: 14px;
    line-height: 17px;
    padding: 3px 7px;
  }

}

